<template>
  <v-container class="mt-0">
    <v-row>
      <v-col class="col-12 col-md-3">
        <v-card outlined>
          <v-card-title class="font-weight-medium">
            {{ $ml.get("integrations") }}
          </v-card-title>
          <!-- <v-divider></v-divider>
          <v-card-text class="text-uppercase font-weight-medium text--disabled">
            <v-icon disabled v-text="'mdi-shape-square-rounded-plus'" left />
            {{ $ml.get("request_integration_activation") }}
          </v-card-text> -->
        </v-card>
      </v-col>
      <!-- PONTOMAIS -->
      <v-col class="col-12 col-md-8">
        <integration-info
          :hasProduct="hasPontoMaisIntegration"
          :descriptionKey="'ponto_mais_control_unavailable'"
        >
          <v-card class="pa-2" outlined :loading="loadingTokens">
            <v-card-title class="text--secondary">
              {{ $ml.get("ponto_mais_control") }}
            </v-card-title>

            <v-card-subtitle>
              {{ $ml.get("ponto_mais_integration_subtitle") }},
              <a
                :href="pontoMaisIntegrationDocLink"
                class="font-weight-medium text-lowercase"
                target="__blank"
              >
                {{ $ml.get("more_link_text") }}.
              </a>
            </v-card-subtitle>

            <v-card-text class="mt-4">
              <v-row class="subtitle-1 black--text mb-1">
                <v-col class="col-12 pb-0">
                  {{ $ml.get("current_token") }}
                  <span class="red--text">*</span>
                </v-col>
                <v-col class="col-12 col-md-10">
                  <v-text-field
                    :disabled="!hasPontoMaisIntegration"
                    :value="pontoMaisToken"
                    :loading="loadingTokens"
                    hide-details
                    readonly
                    outlined
                    append-icon="mdi-pencil"
                    @click="showUpdatePontoMaisTokenDialog = true"
                    @click:append="showUpdatePontoMaisTokenDialog = true"
                  />
                </v-col>
              </v-row>

              <span class="red--text">*</span>
              {{ $ml.get("indicates_required_field") }}
            </v-card-text>
          </v-card>
        </integration-info>
      </v-col>
      <!-- PONTOMAIS -->
      <v-col class="col-12 col-md-8" offset-md="3">
        <integration-info
          :hasProduct="hasPontoMaisIntegration"
          :descriptionKey="'ponto_mais_control_unavailable'"
        >
          <v-card class="pa-2" outlined :loading="loadingTokens">
            <v-card-title class="text--secondary">
              {{ $ml.get("autologout_by_pontomais") }}
            </v-card-title>

            <v-card-subtitle>
              {{ $ml.get("copy_paste_pontomais_autologout_function") }}
            </v-card-subtitle>

            <v-card-text class="mt-2">
              <v-row class="subtitle-1 black--text mb-1">
                <v-col class="col-12 pb-0">
                  {{ $ml.get("current_webhook") }}
                </v-col>
                <v-col class="col-12 col-md-10">
                  <v-list-item
                    class="outlined"
                    append-icon="mdi-reload"
                    :loading="loadingTokens"
                    :disabled="!hasAdIntegrationPermission"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ref="pontomaisAutologoutFunction"
                        v-text="pontomaisAutologoutFunction"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-text="'mdi-content-copy'"
                            @click.stop="copyAutologoutFunction"
                          />
                        </template>
                        <span>{{ $ml.get("copy") }}</span>
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </integration-info>
      </v-col>
      <!-- SENIOR -->
      <v-col class="col-12 col-md-8" offset-md="3">
        <integration-info
          :hasProduct="hasPontoMaisIntegration"
          :descriptionKey="'ponto_mais_control_unavailable'"
        >
          <v-card class="pa-2" outlined :loading="loadingTokens">
            <v-card-title class="text--secondary">
              {{ $ml.get("marcacao_ponto_senior_control") }}
            </v-card-title>

            <v-card-subtitle>
              {{ $ml.get("senior_credentials_description") }}
              <a
                :href="seniorExternalIntegrationLink"
                class="font-weight-medium text-lowercase"
                target="__blank"
              >
                {{ $ml.get("more_link_text") }}.
              </a>
            </v-card-subtitle>

            <v-card-text class="mt-2">
              <v-btn
                :disabled="!hasPontoMaisIntegration"
                text
                outlined
                elevation="1"
                large
                color="accent"
                class="mb-10"
                @click="showUpdateCredentials = true"
              >
                {{
                  hasSeniorCredentials
                    ? $ml.get("edit_credentiais")
                    : $ml.get("config_credentiais")
                }}
              </v-btn>
            </v-card-text>
          </v-card>
        </integration-info>
      </v-col>
      <!-- TANGERINO -->
      <v-col class="col-12 col-md-8" offset-md="3">
        <integration-info
          :hasProduct="hasPontoMaisIntegration"
          :descriptionKey="'tangerino_control_unavailable'"
        >
          <v-card class="pa-2" outlined :loading="loadingTokens">
            <v-card-title class="text--secondary">
              {{ $ml.get("tangerino_control") }}
            </v-card-title>

            <v-card-subtitle>
              {{ $ml.get("tangerino_integration_subtitle") }}
              <a
                :href="tangerinoIntegrationDocLink"
                class="font-weight-medium text-lowercase"
                target="__blank"
              >
                {{ $ml.get("more_link_text") }}.
              </a>
            </v-card-subtitle>

            <v-card-text class="mt-4">
              <v-row class="subtitle-1 black--text mb-1">
                <v-col class="col-12 pb-0">
                  {{ $ml.get("current_token") }}
                  <span class="red--text">*</span>
                </v-col>
                <v-col class="col-12 col-md-10">
                  <v-text-field
                    :disabled="!hasControlPlan"
                    :value="tangerinoToken"
                    :loading="loadingTokens"
                    hide-details
                    readonly
                    outlined
                    append-icon="mdi-pencil"
                    @click="showUpdateTangerinoTokenDialog = true"
                    @click:append="showUpdateTangerinoTokenDialog = true"
                  />
                </v-col>
              </v-row>

              <span class="red--text">*</span>
              {{ $ml.get("indicates_required_field") }}
            </v-card-text>
          </v-card>
        </integration-info>
      </v-col>
      <!-- AD -->
      <v-col class="col-12 col-md-8" offset-md="3">
        <integration-info
          :hasProduct="hasAdIntegrationPermission"
          :descriptionKey="'active_directory_unavailable'"
        >
          <v-card class="pa-2" outlined :loading="loadingTokens">
            <v-card-title class="text--secondary">
              {{ $ml.get("active_directory_sync") }}
            </v-card-title>

            <v-card-subtitle>
              {{ $ml.get("active_directory_integration_subtitle") }},
              <a
                :href="adIntegrationDocLink"
                class="font-weight-medium text-lowercase"
                target="__blank"
              >
                {{ $ml.get("more_link_text") }}.
              </a>
            </v-card-subtitle>

            <v-card-text class="mt-2">
              <v-btn
                :disabled="!hasAdIntegrationPermission"
                text
                outlined
                elevation="1"
                large
                color="accent"
                class="mb-10"
                :href="adSyncSetupLink"
                target="__blank"
              >
                <v-icon v-text="'mdi-tray-arrow-down'" left />
                {{ $ml.get("download_executable") }}
              </v-btn>

              <v-row class="subtitle-1 black--text mb-1">
                <v-col class="col-12 pb-0">
                  {{ $ml.get("current_token") }}
                </v-col>
                <v-col class="col-12 col-md-10">
                  <v-list-item
                    class="outlined"
                    append-icon="mdi-reload"
                    :loading="loadingTokens"
                    :disabled="!hasAdIntegrationPermission"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ref="ADToken"
                        v-text="ADToken"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-text="'mdi-reload'"
                            @click="showUpdateAdTokenDialog = true"
                          />
                        </template>
                        <span>{{ $ml.get("update_ad_token_action") }}</span>
                      </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-text="'mdi-content-copy'"
                            @click.stop="copyADToken"
                          />
                        </template>
                        <span>{{ $ml.get("copy") }}</span>
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </integration-info>
      </v-col>
    </v-row>

    <HeaderDialog
      id="update-ad-token"
      :title="$ml.get('update_ad_token_title')"
      :show="showUpdateAdTokenDialog"
      width="600"
      text
      :action-text="$ml.get('update_ad_token_action')"
      @close="showUpdateAdTokenDialog = false"
      @action="updateAdToken"
    >
      <template v-slot:body>
        <p class="ml-2">{{ $ml.get("update_ad_token_confirm_phrase") }}</p>
      </template>
    </HeaderDialog>

    <HeaderDialog
      id="update-ponto-mais-token"
      :title="$ml.get('ponto_mais_token_integration_title')"
      :show="showUpdatePontoMaisTokenDialog"
      width="650"
      text
      :action-text="$ml.get('save_token')"
      :disabledAction="pontoMaisToken === newPontoMaisToken"
      @close="closeUpdatePontoMaisTokenDialog"
      @action="updatePontoMaisToken"
      :loading="loadingTokens"
    >
      <template v-slot:body>
        <p>
          {{ $ml.get("ponto_mais_integration_subtitle") }}
        </p>
        <v-text-field
          v-if="pontoMaisToken"
          :value="pontoMaisToken"
          :label="$ml.get('current_token')"
          readonly
          class="mt-8"
        />
        <v-text-field
          v-model="newPontoMaisToken"
          :label="$ml.get('new_token')"
          persistent-placeholder
          class="mt-2"
        >
        </v-text-field>
      </template>
    </HeaderDialog>
    <HeaderDialog
      id="update-tangerino-token"
      :title="$ml.get('tangerino_token_integration_title')"
      :show="showUpdateTangerinoTokenDialog"
      width="650"
      text
      :action-text="$ml.get('save_token')"
      :disabledAction="tangerinoToken === newTangerinoToken"
      @close="
        (showUpdateTangerinoTokenDialog = false), (newTangerinoToken = null)
      "
      @action="updateTangerinoToken"
      :loading="loadingTokens"
    >
      <template v-slot:body>
        <p>
          {{ $ml.get("tangerino_integration_subtitle") }}
        </p>
        <v-text-field
          v-if="tangerinoToken"
          :value="tangerinoToken"
          :label="$ml.get('current_token')"
          readonly
          class="mt-8"
        />
        <v-text-field
          v-model="newTangerinoToken"
          :label="$ml.get('new_token')"
          persistent-placeholder
          class="mt-2"
        >
        </v-text-field>
      </template>
    </HeaderDialog>
    <HeaderDialog
      id="config-senior-credentials"
      :title="
        hasSeniorCredentials
          ? $ml.get('edit_credentiais')
          : $ml.get('config_credentiais')
      "
      :show="showUpdateCredentials"
      width="650"
      text
      :action-text="$ml.get('save')"
      :disabledAction="disableCredentialsAction"
      @close="closeConfigCredentials"
      @action="updateSeniorCredentials"
      :loading="loadingCredentials"
    >
      <template v-slot:body>
        <p>
          {{ $ml.get("senior_credentials_description") }}
        </p>
        <v-form ref="credentialsForm">
          <v-text-field
            v-model="seniorAccessKey"
            :label="$ml.get('access_key')"
            persistent-placeholder
            :rules="rules"
            class="mt-2"
          />
          <v-text-field
            v-model="seniorSecret"
            :label="$ml.get('secret')"
            persistent-placeholder
            class="mt-2"
            :rules="rules"
          />
          <v-text-field
            v-model="seniorTenantName"
            :label="$ml.get('tenant_name')"
            persistent-placeholder
            class="mt-2"
            :rules="rules"
          />
        </v-form>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import IntegrationInfo from "@/components/groups/IntegrationInfo.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  billingAddPlan,
  pontoMaisIntegrationDocLink,
  adIntegrationDocLink,
  adSyncSetupLink,
  pontomaisAutologoutWebhookLink,
  seniorExternalIntegrationLink,
  tangerinoIntegrationDocLink,
} from "@/helpers/links";

export default {
  name: "Integrations",
  components: {
    IntegrationInfo,
  },
  data() {
    return {
      ADToken: "",
      newPontoMaisToken: null,
      showUpdateAdTokenDialog: false,
      showUpdatePontoMaisTokenDialog: false,
      showUpdateTangerinoTokenDialog: false,
      loadingTokens: false,
      hasAdIntegrationPermission: false,
      billingAddPlan,
      pontoMaisIntegrationDocLink,
      adIntegrationDocLink,
      adSyncSetupLink,
      tangerinoIntegrationDocLink,
      showUpdateCredentials: false,
      seniorAccessKey: undefined,
      seniorSecret: undefined,
      seniorTenantName: undefined,
      rules: [(v) => (!!v && v.length > 0) || this.$ml.get("required_field")],
      loadingCredentials: false,
      seniorExternalIntegrationLink,
      newTangerinoToken: null,
    };
  },

  computed: {
    ...mapGetters([
      "hasPontoMaisIntegration",
      "pontoMaisToken",
      "company",
      "hasControlPlan",
    ]),

    pontomaisAutologoutFunction() {
      const yourToken = this.$ml.get("your_token");
      return `${pontomaisAutologoutWebhookLink}${this.ADToken || yourToken}`;
    },

    seniorCredentials() {
      return this.company.senior_credentials;
    },

    hasSeniorCredentials() {
      return Object.values(this.seniorCredentials).length;
    },
    tangerinoToken() {
      return this.company.tangerino_token;
    },

    disableCredentialsAction() {
      return !(
        !!this.seniorAccessKey &&
        this.seniorAccessKey.length > 0 &&
        !!this.seniorSecret &&
        this.seniorSecret.length > 0 > 0 &&
        !!this.seniorTenantName &&
        this.seniorTenantName.length > 0 > 0
      );
    },
  },

  async beforeMount() {
    this.setLoadingStatus(true);
    this.loadingTokens = true;
    await this.getADToken();
    this.loadingTokens = false;
    this.setLoadingStatus(false);
    if (this.hasPontoMaisIntegration === undefined) {
      this.checkPontoMaisIntegration();
    }
  },

  methods: {
    ...mapMutations([
      "setSnackbarColor",
      "setSnackbarMessage",
      "showOrHideSnackbar",
      "setSnackbarKey",
      "setLoadingStatus",
      "setSnackbarTimeout",
      "setNewCompanyValue",
      "setHasSeniorCredentials",
    ]),
    ...mapActions([
      "setSnackbarDefault",
      "checkPontoMaisIntegration",
      "getPontoMaisToken",
    ]),

    setSeniorCredentials() {
      const { access_key, tenant, secret } = this.seniorCredentials;

      this.seniorAccessKey = access_key;
      this.seniorSecret = secret;
      this.seniorTenantName = tenant;
    },

    closeConfigCredentials() {
      this.showUpdateCredentials = false;

      if (this.disableCredentialsAction) {
        this.$refs.credentialsForm.reset();
      }
    },

    openLink() {
      window.open(this.billingAddPlan, "_blank");
    },

    copyADToken() {
      const ADToken = this.$refs.ADToken;

      const selection = window.getSelection();
      const range = document.createRange();

      range.selectNodeContents(ADToken);
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand("copy");

      this.setSnackbarDefault({
        color: "success",
        message: "token_copied_successfully",
      });
    },

    copyAutologoutFunction() {
      const pontomaisAutologoutFunction =
        this.$refs.pontomaisAutologoutFunction;
      const selection = window.getSelection();
      const range = document.createRange();

      range.selectNodeContents(pontomaisAutologoutFunction);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");

      this.setSnackbarDefault({
        color: "success",
        message: "logout_webhook_copied_successfully",
      });
    },

    getADToken() {
      this.$axios
        .get("/integrations/ad/token")
        .then(({ data }) => {
          this.ADToken = data.ad_token;
          this.hasAdIntegrationPermission = true;
        })
        .catch(() => {
          this.ADToken = null;
          this.hasAdIntegrationPermission = false;
        });
    },

    updateAdToken() {
      this.$axios
        .put("/integrations/ad/token")
        .then(({ data }) => {
          this.ADToken = data.ad_token;
          this.setSnackbarStatus("success", "success_update_ad_token");
        })
        .catch(() => {
          this.setSnackbarStatus("error", "error_update_ad_token");
        })
        .finally(() => {
          this.showUpdateAdTokenDialog = false;
        });
    },

    updatePontoMaisToken() {
      this.loadingTokens = true;
      const payload = {
        ponto_mais_token: this.newPontoMaisToken,
      };

      this.$axios
        .put("/companies/ponto-mais-token", payload)
        .then(({ data }) => {
          this.setNewCompanyValue(data);
          this.setSnackbarStatus("success", "success_update_ponto_mais_token");
        })
        .catch(({ response }) => {
          const error = response.data.code;
          this.setSnackbarDefault({ color: "error", message: error });
        })
        .finally(() => {
          this.closeUpdatePontoMaisTokenDialog();
          this.loadingTokens = false;
        });
    },

    updateSeniorCredentials() {
      this.loadingCredentials = true;
      const payload = {
        access_key: this.seniorAccessKey,
        secret: this.seniorSecret,
        tenant: this.seniorTenantName,
      };

      this.$axios
        .patch("/integrations/senior/update-credentials", payload)
        .then(() => {
          this.setNewCompanyValue({ senior_credentials: payload });
          this.setHasSeniorCredentials(true);
          this.setSnackbarStatus("success", "save_senior_credentials");

          this.closeConfigCredentials();
        })
        .catch(() => {
          this.setSnackbarStatus(
            "error",
            "error_save_senior_credentials",
            10000
          );
        })
        .finally(() => {
          this.loadingCredentials = false;
        });
    },

    closeUpdatePontoMaisTokenDialog() {
      this.showUpdatePontoMaisTokenDialog = false;
      this.newPontoMaisToken = null;
    },

    setSnackbarStatus(color, message, timeout = null) {
      this.setSnackbarTimeout(timeout);
      this.setSnackbarKey(true);
      this.setSnackbarColor(color);
      this.setSnackbarMessage(message);
      this.showOrHideSnackbar();
    },

    updateTangerinoToken() {
      this.loadingTokens = true;
      const payload = {
        tangerino_token: this.newTangerinoToken,
      };
      this.$axios
        .put("/companies/tangerino-token", payload)
        .then(() => {
          this.setNewCompanyValue(payload);
          this.setSnackbarStatus("success", "success_update_token");
        })
        .catch(({ response }) => {
          const error = response.data.code;
          this.setSnackbarDefault({ color: "error", message: error });
        })
        .finally(() => {
          this.loadingTokens = false;
          this.showUpdateTangerinoTokenDialog = false;
          this.newTangerinoToken = null;
        });
    },
  },
  watch: {
    "company.senior_credentials"(value) {
      const { access_key, tenant, secret } = value;

      this.seniorAccessKey = access_key;
      this.seniorSecret = secret;
      this.seniorTenantName = tenant;
    },
    showUpdateCredentials() {
      this.setSeniorCredentials();
    },
  },
};
</script>
